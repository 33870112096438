import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Testimonials = (props) => {
  // const testimonials = props.data.allMarkdownRemark.edges;
  return (
    <Layout bodyClass="page-testimonials">
      <SEO title="Testimonials" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Testimonials</h1>
              <h2>Comming soon...</h2>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

// export const query = graphql`
//   query TestimonialsQuery {
//     allMarkdownRemark(
//       filter: { fileAbsolutePath: { regex: "/testimonials/" } }
//       sort: { fields: [frontmatter___date], order: DESC }
//     ) {
//       edges {
//         node {
//           html
//           frontmatter {
//             title
//             path
//             name
//             jobtitle
//           }
//         }
//       }
//     }
//   }
// `;

export default Testimonials;
